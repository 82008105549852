<script>
  import axios from "axios";
  import { closeModal, modals } from "svelte-modals";
  import SimpleCrypto from "simple-crypto-js";
  import { onMount } from "svelte";
  import Swal from "sweetalert2";
  import { v4 as uuidv4 } from "uuid";
  export let isOpen;
  export let model;
  let stackIndex = modals.length;
  let workspacename = "";
  onMount(() => console.log(model));
  const AddWorkspace = (e) => {
    e.preventDefault();
    let simpleCrypto = new SimpleCrypto("process.env.SECRET_KEY");
    let usid = localStorage.getItem("_");
    let uid = simpleCrypto.decrypt(usid);
    axios
      .post("https://staging.ws.machine-editique.cloud.geoprod.com/workspace/create", {
        user_id: uid,
        name: workspacename,
      })
      .then((res) => {
        closeModal();
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  async function onUploadPDF(e) {
    const files = e.target.files || (e.dataTransfer && e.dataTransfer.files);
    const file = files[0];
    if (!file || file.type !== "application/pdf")
      return Swal.fire(
        "Il faut selectionnez un fichier PDF",
        "Merci de verifier le type de votre fichier !",
        "question"
      );
    try {
      console.log("addPDF call");
      await copyModel(file);
    } catch (e) {
      console.log(e);
    }
  }
  async function copyModel(fileselected) {
    try {
      const file = new FormData();
      file.append("file", fileselected);
      file.append("workspace_id", model.workspace_id);
      let uuid = uuidv4();
      axios
        .post(
          "https://staging.ws.machine-editique.cloud.geoprod.com/editique_pdf/upload",
          file,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
          }
        )
        .then((res) => {
          axios
            .post(
              "https://staging.ws.machine-editique.cloud.geoprod.com/editique_pdf/setFields",
              {
                data: JSON.parse(model.fieldsIds),
                fileurl: res.data.file_url,
                idModel: uuid,
                modelName: model.modelName + " copie" + Date.now(),
                workspace_id: model.workspace_id,
              }
            )
            .then((res) => {
              console.log(res.data);
              closeModal()
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Model copied successfully",
                showConfirmButton: false,
                timer: 1500,
              }).then(()=> window.location.reload());
            })
            .catch((error) => console.log(error));
        })
        .catch((err) => console.log(err));
    } catch (e) {
      console.log("Failed to add pdf.");
      throw e;
    }
  }
</script>

{#if isOpen}
  <!-- <div role="dialog" class="modal">
      <div class="contents">
        <h2>{title}</h2>
        <p>{message}</p>
        <div
          class="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
  
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div
              class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
            >
              <div
                class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
              >
                <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div class="sm:flex sm:items-start">
                    <div
                      class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
                    >
                      <svg
                        class="h-6 w-6 text-red-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 10.5v3.75m-9.303 3.376C1.83 19.126 2.914 21 4.645 21h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 4.88c-.866-1.501-3.032-1.501-3.898 0L2.697 17.626zM12 17.25h.007v.008H12v-.008z"
                        />
                      </svg>
                    </div>
                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3
                        class="text-lg font-medium leading-6 text-gray-900"
                        id="modal-title"
                      >
                        Creation d'un espace de travail
                      </h3>
                      <div class="mb-6">
                        <label
                          for="default-input"
                          class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >Nom d'espace</label
                        >
                        <input
                          type="text"
                          id="default-input"
                          bind:value={workspacename}
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
                >
                  <button
                    type="button"
                    on:click={AddWorkspace}
                    class="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    >Confirmer</button
                  >
                  <button
                    type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    on:click={closeModal}>Cancel</button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  <div
    class="relative z-10"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <!--
          Background backdrop, show/hide based on modal state.
      
          Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        -->
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div
        class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
      >
        <!--
              Modal panel, show/hide based on modal state.
      
              Entering: "ease-out duration-300"
                From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                To: "opacity-100 translate-y-0 sm:scale-100"
              Leaving: "ease-in duration-200"
                From: "opacity-100 translate-y-0 sm:scale-100"
                To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            -->
        <div
          class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div
                class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-folders"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M9 4h3l2 2h5a2 2 0 0 1 2 2v7a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2"
                  />
                  <path
                    d="M17 17v2a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2h2"
                  />
                </svg>
              </div>
              <div
                class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left flex-col justify-center items-center"
              >
                <h1
                  class="text-lg font-medium leading-6 text-gray-900"
                  id="modal-title"
                >
                  Creation d'une copie du model {model.modelName}
                </h1>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">Upload new file</p>
                </div>

                <div class="flex-col justify-center items-center">
                  <label
                    class="cursor-pointer"
                    for="pdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-cloud-upload ml-auto mr-auto"
                      width="50"
                      height="50"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1"
                      />
                      <polyline points="9 15 12 12 15 15" />
                      <line x1="12" y1="12" x2="12" y2="21" />
                    </svg>
                  </label>
                  <input
                    type="file"
                    name="pdf"
                    id="pdf"
                    class="hidden"
                    on:change={onUploadPDF}
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            <!--  <button
              type="button"
              class="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              >Copier</button
            > -->
            <button
              type="button"
              on:click={closeModal}
              class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >Cancel</button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}

<style>.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* allow click-through to backdrop */
  pointer-events: none;
}

.contents {
  min-width: 240px;
  border-radius: 6px;
  padding: 16px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  pointer-events: auto;
}

h2 {
  text-align: center;
  font-size: 24px;
}

p {
  text-align: center;
  margin-top: 16px;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL1VwbG9hZE1vZGFsLnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDQTtFQUNFLGVBQWU7RUFDZixNQUFNO0VBQ04sU0FBUztFQUNULFFBQVE7RUFDUixPQUFPO0VBQ1AsYUFBYTtFQUNiLHVCQUF1QjtFQUN2QixtQkFBbUI7RUFFbkIsb0NBQW9DO0VBQ3BDLG9CQUFvQjtBQUN0Qjs7QUFFQTtFQUNFLGdCQUFnQjtFQUNoQixrQkFBa0I7RUFDbEIsYUFBYTtFQUNiLGlCQUFpQjtFQUNqQixhQUFhO0VBQ2Isc0JBQXNCO0VBQ3RCLDhCQUE4QjtFQUM5QixvQkFBb0I7QUFDdEI7O0FBRUE7RUFDRSxrQkFBa0I7RUFDbEIsZUFBZTtBQUNqQjs7QUFFQTtFQUNFLGtCQUFrQjtFQUNsQixnQkFBZ0I7QUFDbEIiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvVXBsb2FkTW9kYWwuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG4ubW9kYWwge1xuICBwb3NpdGlvbjogZml4ZWQ7XG4gIHRvcDogMDtcbiAgYm90dG9tOiAwO1xuICByaWdodDogMDtcbiAgbGVmdDogMDtcbiAgZGlzcGxheTogZmxleDtcbiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG5cbiAgLyogYWxsb3cgY2xpY2stdGhyb3VnaCB0byBiYWNrZHJvcCAqL1xuICBwb2ludGVyLWV2ZW50czogbm9uZTtcbn1cblxuLmNvbnRlbnRzIHtcbiAgbWluLXdpZHRoOiAyNDBweDtcbiAgYm9yZGVyLXJhZGl1czogNnB4O1xuICBwYWRkaW5nOiAxNnB4O1xuICBiYWNrZ3JvdW5kOiB3aGl0ZTtcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAganVzdGlmeS1jb250ZW50OiBzcGFjZS1iZXR3ZWVuO1xuICBwb2ludGVyLWV2ZW50czogYXV0bztcbn1cblxuaDIge1xuICB0ZXh0LWFsaWduOiBjZW50ZXI7XG4gIGZvbnQtc2l6ZTogMjRweDtcbn1cblxucCB7XG4gIHRleHQtYWxpZ246IGNlbnRlcjtcbiAgbWFyZ2luLXRvcDogMTZweDtcbn1cbiJdfQ== */</style>
